import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../components/common/Breadcrumb'
import { useDispatch, useSelector } from 'react-redux';
import { getAllChannelOrders } from '../../Store/channelOrders/channelOrdersActions';
import ChannelOrdersItems from './channelOrdersItems';


const ChannelOrders = () => {

    const dispatch = useDispatch()

    const allChannelOrdersData = useSelector((state) => state.channelOrdersData)


    const [allChannelOrders, setAllChannelOrders] = useState([])

    useEffect(() => {
        dispatch(getAllChannelOrders())
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        allChannelOrdersData.sort((a, b) => new Date(b.date) - new Date(a.date));

      setAllChannelOrders(allChannelOrdersData)
    }, [allChannelOrdersData])
    

    console.log('all channel orders data is', allChannelOrders)

    



    return (
        <React.Fragment>
            <div className='overflow-y-auto bg-white mb-12 w-full'>
                <div className='w-full'>
                    <Breadcrumb breadcrumbItem="Channel Orders" title="Orders" />
                </div>

                <div className='rounded-2xl'>
                    <table className='table-fixed py-2 my-2  '>
                        <thead className=' bg-blue-100 text-gray-700 rounded-2xl'>
                            <tr className='w-full'>
                                <th className=' text-sm font-bold w-1/12'>Order Date</th>
                                <th className=' text-sm font-bold w-2/12'>Product Details</th>
                                <th className=' text-sm font-bold w-1/12'>Consignee Details</th>
                                <th className=' text-sm font-bold w-2/12'>Weight & Dimensions</th>
                                <th className=' text-sm font-bold w-1/12'>Channel</th>
                                <th className=' text-sm font-bold w-1/12'>Shipment Status</th>
                                <th className=' text-sm font-bold w-1/12'>Action</th>
                            </tr>
                        </thead>
                        {/* <tbody>
                            {currentItems.map((element) => {
                                return <AllOrdersItems key={element._id} data={element} />
                            })}
                        </tbody> */}
                        <tbody>
                            {allChannelOrders.map((element) => {
                                return <ChannelOrdersItems key={element._id} data={element} />
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ChannelOrders
