import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllOrders } from '../../Store/orders/ordersActions';
import { getAllWalletTransaction } from '../../Store/wallet/walletDataAction'
import Toast from '../../components/toast/Toast';




const AllOrdersItems = (props) => {
  const { data, onCheckboxChange, isMasterChecked, selectedAwbs } = props;


  const dispatch = useDispatch()

  const [cancelBoxVisibility, setCancelBoxVisibility] = useState("hidden")
  const [returnBoxVisibility, setReturnBoxVisibility] = useState("hidden")
  const [toastArgs, setToastArgs] = useState(null)
  const [isChecked, setIsChecked] = useState(isMasterChecked);



  const showToast = (message, type) => {
    setToastArgs({
      message,
      type
    })
    setTimeout(() => {
      setToastArgs(null)
    }, 3000);
  }


  useEffect(() => {
    setIsChecked(isMasterChecked);
  }, [isMasterChecked]);

  useEffect(() => {
    if (!isMasterChecked) {
      setIsChecked(selectedAwbs.includes(data.awbNo));
    }
  }, [selectedAwbs, isMasterChecked, data.awbNo]);


  const capitalizeFirstWord = (input) => {
    if (typeof input !== 'undefined') {
      var word = input.trim().replace(/[\s\t]+/g, ' ');
      return word.split(' ').map(word => word[0].toUpperCase() + word.slice(1)).join(' ');
    };
  }

  const handleCancelBox = () => {
    setCancelBoxVisibility('block')
  }

  const badgeColor = (input) => {
    switch (input) {
      case "Manifested":
      case "Out For Delivery":
      case "In Transit":
      case "Delivery Destination":
      case "Return Manifested":
      case "Return Scheduled":
      case "Return Dispatched":
      case "Return In Transit":
      case "Return Delivery Destination":
      case "Return Out For Delivery":
        return "green";

      case "Not Picked":
      case 'RTO In Transit':
      case 'RTO Delivery Destination':
      case 'RTO Out For Delivery':
      case "Cancelled":
      case "Return Cancelled":
      case "On Hold":
        return "red";

      case "Delivered":
      case "RTO Delivered":
      case "Return Delivered":
        return "blue";

      default: return "green"
    }
  };

  const handleDivOnClick = (e) => {
    e.preventDefault()
    setCancelBoxVisibility("hidden")
    setReturnBoxVisibility("hidden")
  }

  const cancelShipment = async (e) => {
    e.preventDefault()

    if (data.shipmentStatus !== 'Cancelled') {
      const response = await fetch('https://api.couriero.in/cancel-shipment/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': localStorage.getItem('token')
        },
        body: JSON.stringify({
          awbNo: `${data.awbNo}`,
        })
      })

      const result = await response.json()

      console.log(result)

      dispatch(getAllOrders())
      dispatch(getAllWalletTransaction())
      setCancelBoxVisibility("hidden")

    } else {
      alert('shipment already cancelled')
    }
  }

  const handleReturnBox = () => {
    setReturnBoxVisibility('block')
  }

  const returnShipment = async (e) => {
    e.preventDefault()
    if (data.shipmentStatus === 'Delivered') {
      const response = await fetch('https://api.couriero.in/return-shipment/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': localStorage.getItem('token')
        },
        body: JSON.stringify({
          awbNo: `${data.awbNo}`,
        })
      })

      const result = await response.json()

      console.log(result)

      dispatch(getAllOrders())
      dispatch(getAllWalletTransaction())
      setReturnBoxVisibility("hidden")
    } else {
      alert('shipment can not be returned')
    }

  }

  const downloadLabel = async () => {
    const response = await fetch('https://api.couriero.in/shipping-label/single', {
      method: 'POST',
      headers: {
        'auth-token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        awbNo: data.awbNo
      })
    })

    const result = await response.json()
    if (result.status === 'success') {
      showToast('Downloading Shipping Label...Please Wait !!', 'success')
    } else {
      showToast('Error in downloading Shipping Label', 'error')
    }

    const base64Data = await result.pdf



    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a blob from the byte array
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${data.orderId}.pdf`;

    link.click()


  }

  const actionButton = (status) => {
    switch (status) {
      case 'Manifested':
      case 'Out For Pickup':
        return (
          <div className='flex flex-col items-start pl-1'>
            <Link
              to='#'
              className='py-1 text-blue-700 flex flex-row items-center'
            >
              <i className="fa-solid fa-print"></i>
              <button className='text-xs ml-2  bg-blue-500 text-white rounded-2xl px-2' onClick={downloadLabel}>Label</button>
            </Link>
            <Link
              to='#'
              className='py-1 text-green-500'
            >
              <i className="fa-solid fa-pen"></i>
              <button className='text-xs ml-2  bg-green-400 text-white rounded-2xl px-2'>Edit</button>
            </Link>
            <Link
              to='#'
              className='py-1 text-red-600'
            >
              <i className="fa-solid fa-trash-can" ></i>
              <button className='text-xs ml-2  bg-red-400 text-white rounded-2xl px-2' onClick={handleCancelBox}>Cancel</button>
            </Link>
          </div>
        );
      case 'In Transit':
        return (
          <div className='flex flex-col items-start pl-1'>
            <Link
              to='/'
              className='py-1 text-green-500'
            >
              <i className="fa-solid fa-pen"></i>
              <button className='text-xs ml-2  bg-green-400 text-white rounded-2xl px-2'>Edit</button>
            </Link>
            <Link
              to='#'
              className='py-1 text-red-600'
            >
              <i className="fa-solid fa-trash-can" ></i>
              <button className='text-xs ml-2  bg-red-400 text-white rounded-2xl px-2' onClick={handleCancelBox}>Cancel</button>
            </Link>
          </div>
        );
      case 'Not Picked':
        return (
          <div className='flex flex-col items-start pl-1'>
            <Link
              to='#'
              className='py-1 text-red-600'
            >
              <i className="fa-solid fa-trash-can" ></i>
              <button className='text-xs ml-2  bg-red-400 text-white rounded-2xl px-2' onClick={handleCancelBox}>Cancel</button>
            </Link>
          </div>
        );
      case 'Out For Delivery':
        return (
          <div className='flex flex-col items-start pl-1'>
            <h3>No Actions Available</h3>
          </div>
        );
      case 'Cancelled':
        return (
          <div className='flex flex-col items-start pl-1'>
            <h3>No Actions Available</h3>
          </div>
        );
      case 'Delivery Destination':
        return (
          <div className='flex flex-col items-start pl-1'>
            <Link
              to='/'
              className='py-1 text-green-500'
            >
              <i className="fa-solid fa-pen"></i>
              <button className='text-xs ml-2  bg-green-400 text-white rounded-2xl px-2'>Edit</button>

            </Link>
            <Link
              to='#'
              className='py-1 text-red-600'
            >
              <i className="fa-solid fa-trash-can" ></i>
              <button className='text-xs ml-2  bg-red-400 text-white rounded-2xl px-2' onClick={handleCancelBox}>Cancel</button>
            </Link>
          </div>
        );
      case 'Delivered':
        return (
          <div className='flex flex-col items-start pl-1'>
            <Link
              to='#'
              className='py-1 text-green-500'
            >
              <i className="fa-solid fa-right-left"></i>
              <button className='text-xs ml-2  bg-green-400 text-white rounded-2xl px-2' onClick={handleReturnBox}>Return</button>

            </Link>
          </div>
        );
      case 'RTO In Transit':
        return (
          <div className='flex flex-col items-start pl-1'>
            <Link
              to='#'
              className='py-1 text-blue-700'
            >
              <i className="fa-solid fa-arrow-rotate-left"></i>
              <button className='text-xs bg-blue-500 text-white rounded-2xl px-1'>Re-Attempt</button>

            </Link>
          </div>
        );
      case 'RTO Delivery Destination':
        return (
          <div className='flex flex-col items-start pl-1'>
            <Link
              to='#'
              className='py-1 text-blue-700'
            >
              <i className="fa-solid fa-arrow-rotate-left"></i>
              <button className='text-xs bg-blue-500 text-white rounded-2xl px-1'>Re-Attempt</button>

            </Link>
          </div>
        );
      case 'RTO Out For Delivery':
        return (
          <div className='flex flex-col items-start pl-1'>
            <h3>No Actions Available</h3>
          </div>
        );
      case 'RTO Delivered':
        return (
          <div className='flex flex-col items-start pl-1'>
            <h3>No Actions Available</h3>
          </div>
        );
      case 'Return Manifested':
      case 'Return Scheduled':
        return (
          <div className='flex flex-col items-start pl-1'>
            <Link
              to='#'
              className='py-1 text-blue-700 flex flex-row items-center'
            >
              <i className="fa-solid fa-print"></i>
              <button className='text-xs ml-2  bg-blue-500 text-white rounded-2xl px-2' onClick={downloadLabel}>Label</button>
            </Link>
            <Link
              to='#'
              className='py-1 text-red-600'
            >
              <i className="fa-solid fa-trash-can" ></i>
              <button className='text-xs ml-2  bg-red-400 text-white rounded-2xl px-2' onClick={handleCancelBox}>Cancel</button>

            </Link>
          </div>
        );
      case 'Return Out For Delivery':
      case 'Return Delivered':
      case 'Return Cancelled':
      case 'Return Out For Pickup':
        return (
          <div className='flex flex-col items-start pl-1'>
            <h3>No Actions Available</h3>
          </div>
        );
      case 'Return In Transit':
      case 'Return Delivery Destination':
        return (
          <div className='flex flex-col items-start pl-1'>
            <Link
              to='#'
              className='py-1 text-red-600'
            >
              <i className="fa-solid fa-trash-can" ></i>
              <button className='text-xs ml-2  bg-red-400 text-white rounded-2xl px-2' onClick={handleCancelBox}>Cancel</button>

            </Link>
          </div>
        );
      case 'On Hold':
        return (
          <div className='flex flex-col items-start pl-1'>
            <h3>Recharge Wallet to Release Shipments</h3>
          </div>
        );
      default: return (
        <div className='flex flex-col items-start pl-1'>
          <Link
            className='py-1 text-blue-700 flex flex-row items-center '
            onClick={downloadLabel}
          >
            <i className="fa-solid fa-print"></i>
            <button className='text-xs ml-2  bg-blue-500 text-white rounded-2xl px-2'>Label</button>

          </Link>

          <Link
            to='/'
            className='py-1 text-green-500'
          >
            <i className="fa-solid fa-pen"></i>
            <button className='text-xs ml-2  bg-green-400 text-white rounded-2xl px-2'>Edit</button>

          </Link>
          <Link
            to='#'
            className='py-1 text-red-600'
          >
            <i className="fa-solid fa-trash-can" ></i>
            <button className='text-xs ml-2  bg-red-400 text-white rounded-2xl px-2' onClick={handleCancelBox}>Delete</button>

          </Link>
        </div>
      );
    }
  }

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    onCheckboxChange(data.awbNo, e.target.checked);
    console.log('checkbox tick working')
  };

  return (
    <React.Fragment>
      <tr className=' text-gray-700'>
        {toastArgs !== null && <Toast toastArgs={toastArgs} />}
        <td className="p-2 w-2 border">
          <div className="flex items-center">
            <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100  " onChange={handleCheckboxChange} checked={isChecked} />
          </div>
        </td>
        <td className='text-xs cursor-pointer hover:underline border px-2  hover:text-blue-800 font-semibold text-blue-600'>
          <Link to={`/orders/details/${data.awbNo}`}>
            {data.orderId}
          </Link>
        </td>
        <td className='text-xs px-1 font-semibold border text-center'>{data.orderDate.split("T")[0]}</td>
        <td className='text-xs items-center px-1 border py-4' >
          <div className='flex flex-col items-center align-middle'>
            <div className=''>
              <img src={data.logo} className="w-20 h-3 " alt={data.companyName} />
            </div>
            <div className='py-1'>
              <p className='font-semibold text-blue-600 text-sm '><span className='font-medium text-xs  '>Awb No. : </span>{data.awbNo}</p>
              <p className='font-semibold  '><span className='font-medium text-xs'>Courier : </span> {data.companyName}</p>
              <p className='font-semibold  '><span className='font-medium text-xs'>Pickup Address : </span>{capitalizeFirstWord(data.pickupAddressName)}</p>
            </div>
          </div>
        </td>
        <td className=' items-center align-middle px-1 border'>
          <p className='font-semibold text-sm'><span className='font-medium text-xs'>Name : </span>{capitalizeFirstWord(data.productName)}</p>
          <p className='font-semibold text-sm'><span className='font-medium text-xs'>Amount : </span>₹ {data.productAmount}</p>
          <p className='font-semibold text-sm'><span className='font-medium text-xs'>Order Type : </span><span className='rounded-2xl bg-green-200 px-2'>{data.productOrderType}</span></p>
        </td>
        <td className=' px-2 border items-center'>
          <p className='font-semibold text-base '>{capitalizeFirstWord(data.consigneeName)}</p>
          <p className='font-semibold text-xs break-words w-40  '>{data.consigneeEmail}</p>
          <p className='font-semibold text-xs  '>{data.consigneeMobNo}</p>
        </td>
        <td className='text-xs font-semibold pl-1 border text-left'>
          <div>
            <p className='text-xs font-semibold'><span className='font-medium text-xs'>Dimension : </span><span>{data.shipmentLength} X {data.shipmentBredth} X {data.shipmentHeight} cm</span></p>
            <p className='text-xs font-semibold'><span className='font-medium text-xs'>Weight : </span>{data.shipmentWeight} Kg</p>
            <p className='text-xs font-semibold'><span className='font-medium text-xs'>Vol. Weight : </span>{data.shipmentVolWeight} Kg</p>
            <p className='text-xs font-semibold'><span className='font-medium text-xs'>Charged Weight : </span><span className='rounded-2xl bg-green-200 px-2'>{data.shipmentChargedWeight} Kg</span></p>
          </div>
        </td>
        <td className='text-xs font-semibold border align-middle text-center'>
          <div className={`bg-${badgeColor(data.shipmentStatus)}-200 rounded-2xl py-2 mx-4 text-${badgeColor(data.shipmentStatus)}-700 px-2`}>{data.shipmentStatus}</div>
        </td>
        { }
        <td className='text-sm text-center border '>
          {actionButton(data.shipmentStatus)}

          <div className={`${cancelBoxVisibility} fixed inset-0  z-[1000] flex justify-center items-center`}>
            <form onSubmit={cancelShipment} className='absolute top-0 bottom-0 left-0 right-0  z-[1000] flex justify-center items-center'>
              <div className='opacity-60 blur-lg fixed w-full h-full bg-black' onClick={handleDivOnClick}>
              </div>
              <div className='bg-white w-[25rem] h-[22rem] rounded-2xl shadow-2xl text-gray-500 block absolute'>
                <div className=''>
                  <div className='text-center p-4 m-1  bg-red-200 mt-1 rounded-2xl'>
                    <h3 className='text-lg font-bold'>Cancel Shipment</h3>
                  </div>
                  <div className='text-center flex flex-col px-4'>
                    <h3 className='my-16 text-lg font-medium'>Are you Sure that you want to cancel this shipment ?</h3>
                    <div className='flex flex-row items-center justify-center my-2'>
                      <button className='mx-2 bg-green-400 rounded-2xl px-4 text-white' onClick={handleDivOnClick}>Back</button>
                      <button className='mx-2 bg-red-400 rounded-2xl px-4 text-white' type='submit'>Yes, Cancel</button>
                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>

          <div className={`${returnBoxVisibility} fixed inset-0  z-[1000] flex justify-center items-center`}>
            <form onSubmit={returnShipment} className='absolute top-0 bottom-0 left-0 right-0  z-[1000] flex justify-center items-center'>
              <div className='opacity-60 blur-lg fixed w-full h-full bg-black' onClick={handleDivOnClick}></div>
              <div className='bg-white w-[25rem] h-[22rem] rounded-2xl shadow-2xl text-gray-500 block absolute'>
                <div className=''>
                  <div className='text-center p-4 m-1  bg-red-200 mt-1 rounded-2xl'>
                    <h3 className='text-lg font-bold'>Arrange return for this Shipment</h3>
                  </div>
                  <div className='text-center flex flex-col px-4'>
                    <h3 className='my-16 text-lg font-medium'>Are you Sure that you want to arrange return for this shipment ?</h3>
                    <div className='flex flex-row items-center justify-center my-2'>
                      <button className='mx-2 bg-green-400 rounded-2xl px-4 text-white' onClick={handleDivOnClick}>Back</button>
                      <button className='mx-2 bg-red-400 rounded-2xl px-4 text-white' type='submit'>Yes</button>
                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>

        </td>
      </tr>

    </React.Fragment>
  )
}

export default AllOrdersItems
