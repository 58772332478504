import React from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const PrivateRoutes = () => {
  const auth = localStorage.getItem('token');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);  // Get search parameters
  const shop = searchParams.get('shop');  // Extract 'shop' param if available
  const shopifyId = searchParams.get('id')
  let navigate = useNavigate();


  const saveShopDetailsToBackend = async (shop, shopifyId) => {

    console.log('leeeeeeveeeel 1', shop)
    console.log('leeeeeeveeeel 2', shopifyId)

    const response = await fetch("https://api.couriero.in/shopify/updateShopifyChannel", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('token')
      },
      body: JSON.stringify({
        shop,
        shopifyId
      })
    })
    const result = await response.json()
    console.log('Shopify Channel Successfully added', result)

    if (result.status === 'success') {
      navigate('/')
      toast.success('Shopify store added successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });
  } else {
      navigate('/login')
  }
  }

  // If the user is logged in and the shop parameter is present, redirect to the backend for Shopify OAuth
  if (auth && shop) {
    console.log('shop id in auth and shop', shopifyId)
    console.log('shop in auth and shop', shop)

    saveShopDetailsToBackend(shop, shopifyId)
  } else if (auth) {
    return (
      <Outlet />
    )
  } else if (!auth && shop) {
    return (
      <Navigate to={`/login?shop=${shop}&id=${shopifyId}`} />
    )
  } else if (!auth) {
    return (
      <Navigate to="/login" />
    )
  }
};

export default PrivateRoutes;
