import './App.css';
import React from 'react';
import Layout from './components/layout/index';
import { Route, Routes } from "react-router-dom"

// import Hello from './pages/Hello';
import CreateShipment from './pages/createShipment/CreateShipment';
import Login from "./pages/authentication/Login"
import Dashboard from './pages/dashboard/Dashboard';
import SignUp from './pages/authentication/SignUp';
import AllOrders from './pages/AllOrders/AllOrders';
import PrivateRoutes from './pages/authentication/PrivateRoutes';
import Wallet from './pages/Wallet/wallet';
import OrderDetails from './pages/AllOrders/orderDetails';
import Profile from './pages/settings/Profile';
import PodPage from './pages/AllOrders/PodPage';
import CodRemittance from './pages/cod-remittance/CodRemittance';
import ForgotPassword from './pages/authentication/ForgotPassword';
import AdminLogin from './pages/authentication/AdminLogin';
import ChannelIntegration from './pages/settings/channels/ChannelIntegration';
import ChannelList from './pages/settings/channels/ChannelList'
import ShopifyChannel from './pages/settings/channels/ShopifyChannel'
import RateCalculator from './pages/rateCalculator/RateCalculator';
import WeightReconciliation from './pages/weightReconciliation/WeightReconciliation';
import Kyc from './pages/settings/kyc/Kyc';
import BulkOrders from './pages/channelAndBulkOrders/BulkOrders';
import ChannelOrders from './pages/channelAndBulkOrders/channelOrders';


function App() {


  return (
    <React.Fragment>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Layout children={<Dashboard />} />} exact />
          <Route path="/shopifyAuth" element={<Layout children={<Dashboard />} />} exact />
          <Route path="create-shipment" element={<Layout children={<CreateShipment />} />} exact />
          <Route path="/all-orders" element={<Layout children={<AllOrders />} />} exact />
          <Route path="/wallet" element={<Layout children={<Wallet />} />} exact />
          <Route path="/orders/details/:awb" element={<Layout children={<OrderDetails />} />} exact />
          <Route path="/profile" element={<Layout children={<Profile />} />} exact />
          <Route path="/orders/get-pod/:awb" element={<Layout children={<PodPage />} />} exact />
          <Route path="/cod-remittance" element={<Layout children={<CodRemittance />}/>} exact />
          <Route path="/channels" element={<Layout children={<ChannelIntegration />}/>} exact />
          <Route path="/channels/list" element={<Layout children={<ChannelList />} />} exact />
          <Route path="/channels/add/shopify" element={<Layout children={<ShopifyChannel /> } />} exact />
          <Route path="/channel-orders" element={<Layout children={<ChannelOrders />}/>} exact />
          <Route path="/rate-calculator" element={<Layout children={<RateCalculator />} />} exact />
          <Route path="/weight-reconciliation" element={<Layout children={<WeightReconciliation />} />} exact />
          <Route path="/kyc" element={<Layout children={<Kyc />} /> } exact />
          <Route path="/bulk-orders" element={<Layout children={<BulkOrders/>} />} exact />
        </Route>
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/forgot-password' element={<ForgotPassword />}  />
        <Route path='/admin/login-admin' element={<AdminLogin />}/>
      </Routes>
    </React.Fragment>
  );
}

export default App;
