import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../components/common/Breadcrumb'
import shopifyLogo from '../../../Assets/Shopifylogo.png'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllChannels } from '../../../Store/channels/channelsActions'
import { toast } from 'react-toastify'



const ChannelIntegration = () => {

    const dispatch = useDispatch()

    const [activeBoxVisibility, setActiveBoxVisibility] = useState("hidden")


    const channelsData = useSelector((state) => state.channelsData)

    useEffect(() => {
        dispatch(getAllChannels())
        // eslint-disable-next-line
    }, [])


    const handleActiveInactiveBox = () => {
        setActiveBoxVisibility('block')

    }

    const handleDivOnClick = (e) => {
        e.preventDefault()
        setActiveBoxVisibility('hidden')
    }


    const actionButton = (status) => {
        switch (status) {
            case 'active':
                return (
                    <div className='flex flex-col'>
                        <div className='my-1'>
                            <Link
                                to='#'
                                className='py-1 text-blue-500'
                            >
                                <i className="fa-solid fa-pen"></i>
                                <button className='text-xs ml-2  bg-blue-500 text-white rounded-2xl px-2' onClick={handleActiveInactiveBox}>Mark In-Active</button>
                            </Link>
                        </div>
                      
                    </div>
                );
            case 'inactive':
                return (
                    <div className='flex flex-col'>
                        <div className='my-1'>
                            <Link
                                to='#'
                                className='py-1 text-blue-500'
                            >
                                <i className="fa-solid fa-pen"></i>
                                <button className='text-xs ml-2  bg-blue-500 text-white rounded-2xl px-2' onClick={handleActiveInactiveBox}>Mark Active</button>
                            </Link>
                        </div>
                    </div>
                );
            default: return (
                <div>
                    <div className='my-1'>
                        <Link
                            to='#'
                            className='py-1 text-blue-500'
                        >
                            <i className="fa-solid fa-pen"></i>
                            <button className='text-xs ml-2  bg-blue-500 text-white rounded-2xl px-2' onClick={handleActiveInactiveBox}>Mark In-Active</button>
                        </Link>
                    </div>
                   
                </div>

            )
        }
    }

    
    const activeInactiveChannel = async (e, uniqueId, status) => {

        e.preventDefault()

        const response = await fetch('https://api.couriero.in/shopify/active-inactive', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                uniqueId,
                status
            })
        })

        const result = await response.json()

        if (result.status === 'success') {
            setActiveBoxVisibility('hidden')
            dispatch(getAllChannels())

            toast.success(`Shopify store marked ${status === 'active' ? 'In-Active' : 'Active'}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }


    const badgeColor = (input) => {
        switch (input) {
            case "active":
                return "green";
            case "inactive":
                return "gray";
            case "deleted":
                return "red";

            default: return "green"
        }
    };





    return (
        <React.Fragment>
            <div className=' bg-white mb-12 w-full flex flex-col text-gray-600 overflow-y-auto'>
                <div className='w-full'>
                    <Breadcrumb breadcrumbItem="All Channels" title="Settings" />
                </div>
                <div className='flex flex-row w-full justify-end'>
                    <Link
                        to='/channels/list'
                        className='py-1 text-blue-500 items-center mr-10'
                    >
                        <button className='text-xs font-medium ml-2 py-2  bg-blue-500 text-white rounded-2xl px-2'>
                            <span>
                                <i className="fa-solid fa-plus mx-2"></i>
                            </span>
                            Add New Channel</button>
                    </Link>

                </div>

                <div className='w-full md:my-4 md:px-4 rounded-2xl md:pb-5'>
                    <table className='table-fixed py-2 rounded-2xl shadow-2xl md:w-full'>
                        <thead className=' bg-blue-100 text-gray-700 rounded-2xl'>
                            <tr className=''>
                                <th className='py-1 text-sm font-bold w-1/5 border-2'>Channel Name</th>
                                <th className='py-1 text-sm font-bold w-1/5 border-2'>Channel Id</th>
                                <th className='py-1 text-sm font-bold w-1/5 border-2'>Shop</th>
                                <th className='py-1 text-sm font-bold w-1/5 border-2'>Connection Status</th>
                                <th className='py-1 text-sm font-bold w-1/5 border-2'>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {channelsData.map((element) => {
                                return (
                                    <tr key={element._id}>
                                        <td className='text-xs border font-medium text-center py-2 px-4 w-1/5'>
                                            <div className='items-center align-middle flex flex-col'>
                                                <img src={shopifyLogo} alt={element.channelName} className='w-20' />
                                            </div>
                                        </td>
                                        <td className='text-sm border font-medium text-center py-2 px-4 w-1/5'>{element.uniqueId}</td>
                                        <td className='text-sm border font-medium text-center py-2 px-4 w-1/5'>{element.shop}</td>
                                        <td className='text-xs border font-medium text-center py-2 px-4 w-1/5'>
                                            <div className='flex flex-row items-center w-full justify-center'>
                                                <i className={`fa-solid fa-circle text-${badgeColor(element.status)}-500`}></i>
                                                <div className={`text-xs ml-2  bg-white text-${badgeColor(element.status)} rounded-2xl px-2`}>{element.status}</div>
                                            </div>
                                        </td>
                                        <td className='text-xs border font-medium text-center py-2 px-4 w-1/5'>
                                        {actionButton(element.status)}


                                            <div className={`${activeBoxVisibility} fixed inset-0  z-[1000] flex justify-center items-center`}>
                                                <form onSubmit={(e) => activeInactiveChannel(e, element.uniqueId, element.status)} className='absolute top-0 bottom-0 left-0 right-0  z-[1000] flex justify-center items-center'>
                                                    <div className='opacity-60 blur-lg fixed w-full h-full bg-black' onClick={handleDivOnClick}></div>
                                                    <div className='bg-white w-[25rem] h-[22rem] rounded-2xl shadow-2xl text-gray-500 block absolute'>
                                                        <div className=''>
                                                            <div className='text-center p-4 m-1  bg-red-200 mt-1 rounded-2xl'>
                                                                <h3 className='text-lg font-bold'>{element.status === 'active' ? 'Mark in-active' : 'Mark Active'}</h3>
                                                            </div>
                                                            <div className='text-center flex flex-col px-4'>
                                                                <h3 className='my-16 text-lg font-medium'>{`Are you Sure that you want to mark this channel ${element.status === 'active' ? 'In-Active' : 'Active'} ?`}</h3>
                                                                <div className='flex flex-row items-center justify-center my-2'>
                                                                    <button className='mx-2 bg-green-400 rounded-2xl px-4 text-white' onClick={handleDivOnClick}>Back</button>
                                                                    <button className='mx-2 bg-red-400 rounded-2xl px-4 text-white' type='submit'>Yes</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </form>
                                            </div>




                                           
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>


        </React.Fragment>
    )
}

export default ChannelIntegration
