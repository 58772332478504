import React, { useState } from 'react'
import Breadcrumb from '../../components/common/Breadcrumb'
import { useDropzone } from 'react-dropzone'
import * as XLSX from 'xlsx';
import Toast from '../../components/toast/Toast';




const BulkOrders = () => {
    const [toastArgs, setToastArgs] = useState(null)
    const [acceptedRows, setAcceptedRows] = useState([]);
    const [rejectedRows, setRejectedRows] = useState([]);
    const [summarySection, setSummarySection] = useState(false);
    const [isLoading, setIsLoading] = useState(false);



    const showToast = (message, type) => {
        setToastArgs({
            message,
            type
        })
        setTimeout(() => {
            setToastArgs(null)
        }, 3000);
    }

    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 1) {
            showToast('Please upload exactly one file.');
            return;
        }

        const file = acceptedFiles[0];

        if (!file.name.endsWith('.xlsx')) {
            showToast('Only .xlsx files are allowed.', 'error');
            return;
        }

        analyzeData(file);
    };


    const analyzeData = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = event.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            const rows = XLSX.utils.sheet_to_json(worksheet);

            const validRows = [];
            const invalidRows = [];

            rows.forEach((row, index) => {
                if (
                    row.ConsigneeName &&
                    typeof row.ConsigneeName === 'string' &&
                    row.ConsigneeAddress &&
                    row.ConsigneePincode &&
                    /^\d{6}$/.test(row.ConsigneePincode) &&
                    row.ConsigneePhoneNumber &&
                    /^\d{10}$/.test(row.ConsigneePhoneNumber) &&
                    row.ShipmentLength &&
                    typeof row.ShipmentLength === 'number' &&
                    row.ShipmentBreadth &&
                    typeof row.ShipmentBreadth === 'number' &&
                    row.ShipmentHeight &&
                    typeof row.ShipmentHeight === 'number' &&
                    row.ShipmentWeight &&
                    typeof row.ShipmentWeight === 'number' &&
                    row.ProductName &&
                    row.ProductAmount &&
                    typeof row.ProductAmount === 'number' &&
                    row.ProductQuantity &&
                    typeof row.ProductQuantity === 'number' &&
                    row.PaymentMethod &&
                    (row.PaymentMethod === 'cod' || row.PaymentMethod === 'prepaid')

                ) {
                    validRows.push(row);
                } else {

                    const invalid = {
                        ConsigneeName: row.ConsigneeName || 'This column cannot be blank',
                        ConsigneeAddress: row.ConsigneeAddress || 'This column cannot be blank',
                        ConsigneePincode: /^\d{6}$/.test(row.ConsigneePincode) ? row.ConsigneePincode : 'Pin Code should be 6 digit number' || 'This column cannot be blank',
                        ConsigneePhoneNumber: /^\d{10}$/.test(row.ConsigneePhoneNumber) ? row.ConsigneePhoneNumber : 'Phone number should be 10 digits' || 'This column cannot be blank',
                        ShipmentLength: typeof row.ShipmentLength === 'number' ? row.ShipmentLength : 'Shipment length must be a number in cm' || 'This column cannot be blank',
                        ShipmentBreadth: typeof row.ShipmentBreadth === 'number' ? row.ShipmentBreadth : 'Shipment Breadth must be a number in cm' || 'This column cannot be blank',
                        ShipmentHeight: typeof row.ShipmentHeight === 'number' ? row.ShipmentHeight : 'Shipment Height must be a number in cm' || 'This column cannot be blank',
                        ShipmentWeight: typeof row.ShipmentWeight === 'number' ? row.ShipmentWeight : 'Shipment Weight must be a number in grams' || 'This column cannot be blank',
                        ProductName: row.ProductName || 'This column cannot be blank',
                        ProductAmount: typeof row.ProductAmount === 'number' ? row.ProductAmount : 'Product amount should be in INR' || 'This column cannot be blank',
                        ProductQuantity: typeof row.ProductQuantity === 'number' ? row.ProductQuantity : 'Product Quantity should be a number' || 'This column cannot be blank',
                        PaymentMethod: (row.PaymentMethod === 'cod' || row.PaymentMethod === 'prepaid') ? row.PaymentMethod : 'Payment method should be either cod or prepaid' || 'This column cannot be blank'
                    }
                    invalidRows.push(invalid);
                }
            });

            setAcceptedRows(validRows);
            setRejectedRows(invalidRows);
            setSummarySection(true);
        };

        reader.readAsBinaryString(file);
    }
    const handleDownloadButtonClick = () => {
        const ws = XLSX.utils.json_to_sheet(rejectedRows);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Rejected Rows');
        XLSX.writeFile(wb, 'error_report.xlsx');
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const SampleData = [
        ['ConsigneeName', 'ConsigneeAddress', 'ConsigneePincode', 'ConsigneePhoneNumber', 'ConsigneeEmailId', 'ShipmentLength', 'ShipmentBreadth', 'ShipmentHeight', 'ShipmentWeight', 'ProductName', 'ProductAmount', 'ProductQuantity', 'PaymentMethod'],
        ['John Doe', '123 Main St', 123456, 9876543210, 'john@xyz.in', 20, 25, 10, 600, 'Clothes', 2200, 1, 'cod',],
        ['Lisa Jhonson', '456 Next Big Street', 250001, 9812398123, 'lisa@abc.in', 25, 28, 15, 1400, 'Electronics', 3999, 1, 'prepaid']
        // Add more sample data as needed
    ];

    const handleUploadOrders = async () => {
        setIsLoading(true)

        if (isLoading === true) {
            showToast('Processing Your Orders...Please Wait', 'success')
        }

        const response = await fetch('https://api.couriero.in/bulk-orders/upload',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem('token')
            },
            body: JSON.stringify({ acceptedRows })
        })

        const result = await response.json()

        if (result.status === 'success') {
            showToast('Orders uploaded successfully, Check channel Orders', 'success')
            setIsLoading(false)

        } else {
            showToast('Order upload unsuccessful', 'error')
            setIsLoading(false)

        }
        console.log('the result for bulk upload is', result)

    }




    return (
        <React.Fragment>
            <div className='flex flex-col mb-12 w-full overflow-y-auto text-gray-600'>
                <div className='w-full'>
                    <Breadcrumb breadcrumbItem="Bulk Orders Upload" title="Orders" />
                </div>
                {toastArgs !== null && <Toast toastArgs={toastArgs} />}

                <div className='flex flex-col w-full border-2 rounded-3xl bg-white shadow-2xl'>
                    <div className='m-4 p-2 w-full border-b-2 flex flex-row justify-between justify-items-start'>
                        <div className='w-1/2 flex flex-col'>
                            <h1 className='text-base font-bold'>
                                Import Bulk Orders
                            </h1>
                            <h3 className='text-xs'>Retrieve the sample file and substitute its information with your order data. Ensure that all necessary fields are completed.
                                Save the file and proceed to upload it again.</h3>
                        </div>


                        <div className='bg-blue-500 text-white rounded-3xl mx-8 px-2 py-1 my-4'>
                            <button className='flex flow-row items-center' onClick={() => XLSX.writeFile({ SheetNames: ['Sheet1'], Sheets: { Sheet1: XLSX.utils.aoa_to_sheet(SampleData) } }, 'sample_data.xlsx')}>
                                <i className="fa-solid fa-download mx-2 text-xs"></i>
                                <h3 className='text-xs font-medium'>Download Template</h3>
                            </button>
                        </div>
                    </div>

                    <div className='w-full flex my-8 items-center text-center justify-center'>
                        <div {...getRootProps()} className='border-dashed py-10 border-2 px-60 border-blue-500'>
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p>Drop the files here ...</p> :
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                            }
                            <div className='bg-blue-500 text-white rounded-3xl mx-28 my-8 cursor-pointer px-6'>
                                <button className='text-sm font-medium'>Browse & Upload</button>
                            </div>
                        </div>
                    </div>
                    <div className='mx-6 my-4'>
                        <h3 className='text-xs text-center'>Orders that are successful will be routed to the Channel Orders section. You can retrieve your error files from below.</h3>
                    </div>

                </div>

                {summarySection && (
                    <div className='flex flex-col justify-center w-full my-4 px-20'>
                        <div className='flex justify-center my-4 '>
                            <h2 className='text-center font-bold'>Summary</h2>
                        </div>
                        <table className='table-fixed py-2 my-2'>
                            <thead className=' bg-blue-100 text-gray-700'>
                                <tr className='w-full'>
                                    <th className=' text-sm font-bold w-1/4'>Total No of Orders</th>
                                    <th className=' text-sm font-bold w-1/4'>Accepted Orders</th>
                                    <th className=' text-sm font-bold w-1/4'>Rejected Orders</th>
                                    <th className=' text-sm font-bold w-1/4'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className=' text-gray-700'>
                                    <td className="p-2 border text-center">{acceptedRows.length + rejectedRows.length}</td>
                                    <td className="p-2 border text-center">{acceptedRows.length}</td>
                                    <td className="p-2 border text-center">{rejectedRows.length}</td>
                                    <td className="p-2 border text-center">
                                        <div className='flex flex-col'>
                                            <div className='bg-blue-500 text-white rounded-3xl mx-4 px-1 py-1 my-2 flex flrow items-center'>
                                                <i className="fa-solid fa-upload text-xs mx-1"></i>
                                                <button
                                                    onClick={handleUploadOrders}
                                                    className='text-xs font-medium'
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? 'Processing...Please Wait' : 'Process Accepted Orders'}

                                                    

                                                </button>
                                            </div>
                                            <div className='bg-red-500 text-white rounded-3xl mx-4 px-1 py-1 my-2 flex flex-row items-center'>
                                                <i className="fa-solid fa-download mx-1 text-xs"></i>
                                                <button onClick={handleDownloadButtonClick} className='text-xs font-medium'>Download Rejected Orders</button>
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}



            </div>

        </React.Fragment>
    )
}

export default BulkOrders
