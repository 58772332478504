import { all, fork, put, takeEvery } from "redux-saga/effects"
import { GET_ALL_CHANNEL_ORDERS, SET_ALL_CHANNEL_ORDERS } from "./channelOrdersActionTypes"


function* getAllChannelOrders() {

    var allChannelOrders = []


    const response = yield fetch('https://api.couriero.in/channel-orders/all-channel-orders', {
        method: 'GET',
        headers: {
            'auth-token': localStorage.getItem('token')
        }
    })

    const result = yield response.json()


    const shopifyresponse = yield fetch('https://api.couriero.in/shopify/all-shopify-orders', {
        method: 'GET',
        headers: {
            'auth-token': localStorage.getItem('token')
        }
    })

    const shopifyResult = yield shopifyresponse.json()

    allChannelOrders.push(...result, ...shopifyResult)

    //const combinedChannelOrders = allChannelOrders
    yield put({type: SET_ALL_CHANNEL_ORDERS, data: allChannelOrders})
}

function* watchGetAllChannelOrders() {
    yield takeEvery(GET_ALL_CHANNEL_ORDERS, getAllChannelOrders)
}

function* channelOrdersSaga() {
    yield all([
        fork(watchGetAllChannelOrders)
    ])
}

export default channelOrdersSaga